const { storageManager, StorageTypes } = require('@pcid/storage-manager');

const storageConstants = {
	TRUST_TOKEN: 'trustToken',
	REQUEST_STATE: 'requestState',
	REQUEST_STATE_LOGIN: 'requestStateLogin',
	LOGIN_CTX: 'loginCtx',
	RELYING_PARTY: 'relyingParty',
	EMAIL: 'email',
	AUTHFACTOR: 'authFactor',
	RESET_PASSWORD_RELYING_PARTY: 'resetPasswordRelyingParty',
	REMEMBER: 'remember',
	VERIFIER: 'codeVerifier',
	ACCESSTOKEN: 'accessToken',
	CSRF: 'csrf',
	NONCE: 'nonce',
	ID_TOKEN: 'idToken',
	LOCALE: 'locale',
	CORRELATION_ID: 'correlationId',
	CASL: 'casl',
	RELYING_PARTY_TRACKER: 'relyingPartyTracker',
	KMSI_TOKEN: 'kmsiToken',
	KMSI_EMAIL: 'kmsiEmail',
	STATE: 'state',
};

module.exports = {
	storageConstants,
	encodeString: (string) => Buffer.from(string).toString('base64'),
	decodeString: (string) => Buffer.from(string, 'base64').toString('utf-8'),
	getLoginCtxStorage: () => storageManager
		.createOrFetchStorage(storageConstants.LOGIN_CTX),
	getTrustTokenStorage: () => storageManager
		.createOrFetchStorage(storageConstants.TRUST_TOKEN),
	getRequestStateStorage: () => storageManager
		.createOrFetchStorage(storageConstants.REQUEST_STATE, StorageTypes.SESSION),
	getRequestStateLoginStorage: () => storageManager
		.createOrFetchStorage(storageConstants.REQUEST_STATE_LOGIN, StorageTypes.SESSION),
	getRelyingPartyStorage: () => storageManager
		.createOrFetchStorage(storageConstants.RELYING_PARTY, StorageTypes.SESSION),
	getEmailStorage: () => storageManager
		.createOrFetchStorage(storageConstants.EMAIL, StorageTypes.SESSION),
	getResetPasswordRelyingPartyStorage: () => storageManager
		.createOrFetchStorage(storageConstants.RESET_PASSWORD_RELYING_PARTY),
	getRememberedUserStorage: () => storageManager
		.createOrFetchStorage(storageConstants.REMEMBER, StorageTypes.LOCAL),
	getCodeVerifierStorage: () => storageManager
		.createOrFetchStorage(storageConstants.VERIFIER, StorageTypes.SESSION),
	getAccessTokenStorage: () => storageManager
		.createOrFetchStorage(storageConstants.ACCESSTOKEN, StorageTypes.LOCAL),
	getIdTokenStorage: () => storageManager
		.createOrFetchStorage(storageConstants.ID_TOKEN, StorageTypes.LOCAL),
	getCsrfStorage: () => storageManager
		.createOrFetchStorage(storageConstants.CSRF, StorageTypes.SESSION),
	getNonceStorage: () => storageManager
		.createOrFetchStorage(storageConstants.NONCE, StorageTypes.SESSION),
	getLocaleStorage: () => storageManager
		.createOrFetchStorage(storageConstants.LOCALE, StorageTypes.LOCAL),
	getAuthFactorStorage: () => storageManager
		.createOrFetchStorage(storageConstants.AUTHFACTOR, StorageTypes.SESSION),
	getCorrelationIdStorage: () => storageManager
		.createOrFetchStorage(storageConstants.CORRELATION_ID, StorageTypes.SESSION),
	getCaslStorage: () => storageManager
		.createOrFetchStorage(storageConstants.CASL, StorageTypes.SESSION),
	getRelyingPartyTrackerStorage: () => storageManager
		.createOrFetchStorage(storageConstants.RELYING_PARTY_TRACKER, StorageTypes.LOCAL),
	getKmsiTokenStorage: () => storageManager
		.createOrFetchStorage(storageConstants.KMSI_TOKEN, StorageTypes.LOCAL),
	getKmsiEmailStorage: () => storageManager
		.createOrFetchStorage(storageConstants.KMSI_EMAIL, StorageTypes.LOCAL),
	getStateStorage: () => storageManager
		.createOrFetchStorage(storageConstants.STATE, StorageTypes.SESSION),
};
