import Router from 'next/router';
import { stringify } from 'qs';
import constants from '@pcid/analytics/constants';
import { autoFormatMessage } from '@pcid/string-utils';

const { pageName: {
	EXPIRED_PAGE,
	NO_LOGIN_INFO_PAGE,
	DEACTIVATED_PAGE,
	LOCKED_PAGE,
	APP_ERROR_PAGE,
	NOT_FOUND_PAGE,
	NO_TOKEN_PAGE,
	MAINTENANCE,
} } = constants;

const {
	ERROR_SECTION,
	LOGIN_SECTION,
} = constants.pageSection;

/**
 * Oops!
 *
 * Whenever we have a form submission that fails, and we want to dissuade the user
 * from re-submitting the form for whatever reason, we send them to the "Oops!" page.
 * Full credit for the name goes to @mansari.
 *
 * The Oops page has different flavours, or 'reasons', each of which corresponds to
 * a pageViewPayload, a messageId, and a functional component in pages/oops.jsx. To
 * push/replace to the Oops page, just import this file's default export and, and call
 * `oops.camelCaseReason(options);`. Options include 'replace', which will call
 * Router.replace instead of the default Router.push; and 'errorCode' to specify a
 * specific server error code for the analytics payload.
 *
 * The 'reason' and 'errorCode' parameters are passed to the Oops page via the Next
 * Router as query params (not in the asPath, so they remain obscured from the address
 * bar).
 */

export const OopsReasons = {
	NO_LOGIN_INFO: 'NO_LOGIN_INFO',
	EXPIRED: 'EXPIRED',
	DEACTIVATED: 'DEACTIVATED',
	LOCKED: 'LOCKED',
	NOT_FOUND: 'NOT_FOUND',
	NO_TOKEN: 'NO_TOKEN',
	APP_ERROR: 'APP_ERROR',
	MAINTENANCE: 'MAINTENANCE',
};

const getConstants = (reason) => {
	switch (reason) {
		case OopsReasons.NO_LOGIN_INFO: {
			const messageId = 'page.oops.body.noLoginInfo';
			return {
				messageId,
				pageViewPayload: {
					pageName: NO_LOGIN_INFO_PAGE,
					pageSection: ERROR_SECTION,
					errorType: 'application',
					errorMessage: autoFormatMessage(messageId),
				},
			};
		}
		case OopsReasons.EXPIRED: {
			const messageId = 'page.oops.body.expired';
			return {
				messageId,
				pageViewPayload: {
					pageName: EXPIRED_PAGE,
					pageSection: ERROR_SECTION,
					errorType: 'server',
					errorMessage: autoFormatMessage(messageId),
				},
			};
		}
		case OopsReasons.DEACTIVATED: {
			const messageId = 'page.oops.body.deactivated';
			return {
				messageId,
				pageViewPayload: {
					pageName: DEACTIVATED_PAGE,
					pageSection: ERROR_SECTION,
					errorType: 'server',
					errorMessage: autoFormatMessage(messageId),
				},
			};
		}
		case OopsReasons.LOCKED: {
			const messageId = 'page.oops.body.locked';
			return {
				messageId,
				pageViewPayload: {
					pageName: LOCKED_PAGE,
					pageSection: LOGIN_SECTION,
					errorType: 'server',
					errorMessage: autoFormatMessage(messageId),
				},
			};
		}
		case OopsReasons.APP_ERROR: {
			const messageId = 'page.oops.body.appError';
			return {
				messageId,
				pageViewPayload: {
					pageName: APP_ERROR_PAGE,
					pageSection: LOGIN_SECTION,
					errorType: 'application',
					errorMessage: autoFormatMessage(messageId),
				},
			};
		}
		case OopsReasons.MAINTENANCE: {
			const messageId = 'page.oops.body.maintenance';
			return {
				messageId,
				pageViewPayload: {
					pageName: MAINTENANCE,
					pageSection: ERROR_SECTION,
					errorType: 'application',
					errorMessage: autoFormatMessage(messageId),
				},
			};
		}
		case OopsReasons.NO_TOKEN: {
			const messageId = 'page.oops.body.noToken';
			return {
				messageId,
				pageViewPayload: {
					pageName: NO_TOKEN_PAGE,
					pageSection: ERROR_SECTION,
					errorType: 'application',
					errorMessage: autoFormatMessage({ id: messageId, values: { signIn: 'form.common.signIn' } }),
				},
			};
		}
		case OopsReasons.NOT_FOUND:
		default: {
			const messageId = 'page.oops.body.notFound';
			return {
				messageId,
				pageViewPayload: {
					pageName: NOT_FOUND_PAGE,
					pageSection: ERROR_SECTION,
					errorType: 'application',
					errorMessage: autoFormatMessage(messageId),
				},
			};
		}
	}
};

export const getMessageId = (reason) => getConstants(reason).messageId;
export const getPageViewPayload = ({ reason, errorCode }) => ({
	...getConstants(reason).pageViewPayload,
	errorCode,
});

export const isValidOopsReason = (reason) => Object.keys(OopsReasons)
	.some((key) => OopsReasons[key] === reason);

const oops = (reason, { replace, errorCode } = {}) => (
	replace
		? Router.replace
		: Router.push
)(`/oops?${stringify({ reason, errorCode })}`, '/oops');

oops.expired = (options) => oops(OopsReasons.EXPIRED, { replace: true, ...options });
oops.noLoginInfo = (options) => oops(OopsReasons.NO_LOGIN_INFO, { replace: true, ...options });
oops.locked = (options) => oops(OopsReasons.LOCKED, { replace: true, ...options });
oops.deactivated = (options) => oops(OopsReasons.DEACTIVATED, options);
oops.notFound = (options) => oops(OopsReasons.NOT_FOUND, { replace: true, ...options });
oops.noUpdateEmailToken = (options) => oops(OopsReasons.NO_TOKEN, { replace: true, ...options });
oops.appError = (options) => oops(OopsReasons.APP_ERROR, { replace: true, ...options });
oops.maintenance = (options) => oops(OopsReasons.MAINTENANCE, { replace: true, ...options });

export default oops;
