import RequestBuilder from '@pcid/client-request-builder';

export const minimumScore = 2;

export const Integrity = {
	WARNING: 'warning',
	BREACHED: 'breached',
	OK: 'ok',
};

export default (username = '', password) => new RequestBuilder(window.pcid.PASSWORD_POLICY_SERVICE_URL)
	.withData({
		checkPasswordExposure: true,
		username,
		password,
	})
	.post(`/v1/credentials${username ? '' : '/password'}`)
	.then(({ status } = {}) => status)
	.catch(({ response: { status } = {} } = {}) => status)
	.then((status) => {
		switch (status) {
			case 200:
				return { integrity: Integrity.WARNING, policyScore: minimumScore };
			case 401:
				return { integrity: Integrity.BREACHED, policyScore: minimumScore - 1 };
			default:
				return { integrity: Integrity.OK };
		}
	});
