const { RequestBuilder } = require('@common/request-builder');
const stringHash = require('string-hash');
const { startTransaction } = require('@sentry/browser');

const { localeCodes } = require('@pcid/locale-utils');

/**
 * This class extends the RequestBuilder to allow axios-style http verb methods. Eg.
 *
 * ```
 * new RequestBuilder(url).withData({ foo: 'bar' }).post('/users')
 * ```
 *
 * vs.
 *
 * ```
 * new RequestBuilder(url)
 *   .withData({ foo: 'bar' })
 *   .withPath('/users')
 *   .withMethod('POST').execute()
 * ```
 *
 * This class should be used for all front-end requests. There is also a file at
 * `server/serverRequestBuilder.js`, which implements a factory for making
 * SugaryRequestBuilders with extra config specific to the back-end.
 */
class SugaryRequestBuilder extends RequestBuilder {
	constructor(rootUrl = '') {
		super(rootUrl);
		// Generating axios-style request method aliases
		[
			'head',
			'options',
			'get',
			'post',
			'patch',
			'put',
			'delete',
		].forEach((method) => {
			this[method] = (path) => (
				this
					.withMethod(method)
					.withPath(path)
					.execute()
			);

			this.transformResponse = [(data, headers = {}) => (
				/application\/json/.test(headers['content-type'])
					? JSON.parse(data)
					: data
			)];

			this.errorHandler = (err) => { throw err; };
		});
	}

	execute() {
		return super.execute().catch(this.errorHandler);
	}

	withErrorHandler(handler) {
		this.errorHandler = handler;
		return this;
	}
}

const hashEmail = (email = '') => {
	const [username, domain] = email.split('@');
	return `${stringHash(username)}@${domain}`;
};

const decodeState = (state) => {
	try {
		const decoded = global.window ? window.atob(state) : Buffer.from(state, 'base64').toString('utf-8');
		return JSON.parse(decoded);
	} catch (error) {
		// handle the case where state is a JSON non-parsable
		return {};
	}
};

const getRequestLocale = ({ headers: { 'accept-language': language = localeCodes.EN } = {} } = {}) => language.split(';')[0].split(',')[0];

const getSentryTrace = () => {
	const transaction = startTransaction();
	if (!transaction) {
		return 'no-trace-id-no-span-id';
	}
	const { traceId, spanId } = transaction;
	return `${traceId}-${spanId}`;
};

module.exports = {
	RequestBuilder: SugaryRequestBuilder,
	getRequestLocale,
	hashEmail,
	decodeState,
	getSentryTrace,
};
