import React from 'react';
import PropTypes from 'prop-types';
import { IntlProvider } from 'react-intl';
import {
	localeCodes,
	parseLanguage,
	isRecognizedLocale,
	localeManager,
} from '@pcid/locale-utils';
import i18nMessages from '@pcid/cache/i18n/allMessages';
import track from '@pcid/analytics/track';

const LocaleContext = React.createContext();

class LocaleProvider extends React.Component {
	constructor(props) {
		super(props);
		const { initialLanguage } = props;
		const locale = `${initialLanguage}-CA`;
		const recognizedLocale = isRecognizedLocale(locale) ? locale : localeCodes.EN;

		this.state = {
			locale: recognizedLocale,
		};
		localeManager.setLocale(recognizedLocale);
	}

	setLocale = (newLocale = localeCodes.EN) => {
		const { locale } = this.state;
		const { updateConfig } = this.props;
		if (isRecognizedLocale(newLocale) && newLocale !== locale) {
			const language = parseLanguage(newLocale);
			track.customLink({
				linkName: 'page-language-change',
				pageLanguage: language,
			});
			localeManager.setLocale(newLocale);
			this.setState({ locale: newLocale });
			updateConfig();
		}
	};

	render() {
		const { children } = this.props;
		const { locale } = this.state;
		const { setLocale } = this;
		const language = parseLanguage(locale);
		const messages = i18nMessages[language];
		return (
			<LocaleContext.Provider value={{ locale, setLocale }}>
				<IntlProvider
					locale={language}
					key={language}
					messages={messages}
				>
					{children}
				</IntlProvider>
			</LocaleContext.Provider>
		);
	}
}

LocaleProvider.propTypes = {
	children: PropTypes.node.isRequired,
	initialLanguage: PropTypes.string.isRequired,
	updateConfig: PropTypes.func,
};

LocaleProvider.defaultProps = {
	updateConfig: () => {},
};

export const withLocale = (Component) => (props) => (
	<LocaleContext.Consumer>
		{(context) => <Component {...context} {...props} />}
	</LocaleContext.Consumer>
);

export default LocaleProvider;
