import { parse } from 'qs';
import Router from 'next/router';
import { initializeSnowplow, EventName } from '@pcid/analytics-helper-library-pcid-snowplow';

import track, { trackSnowplowEvent } from './track';

const snowplowEnvironmentMap = {
	local: 'dev',
	dev: 'dev',
	sit: 'qa',
	prod: 'prod',
};

// we shouldn't track any shallow route change here
export const trackRouteChange = (
	getPageViewPayload, snowplowFlag
) => (url, payload = { shallow: false, hidePageView: false }) => {
	const [pathname, querystring] = url.split('?');
	const query = parse(querystring);
	const {
		gtmPayload,
		snowplowPayload,
	} = getPageViewPayload(pathname, { ...Router.query, ...query });

	if (!payload.shallow
		&& !payload.hidePageView
		&& query?.hidePageView !== 'true'
		&& gtmPayload
	) track.pageView(gtmPayload);
	if (!payload.shallow
		&& !payload.hidePageView
		&& query?.hidePageView !== 'true'
		&& snowplowFlag
		&& snowplowPayload
	) {
		trackSnowplowEvent(snowplowPayload);
	}
};

export const getLink = (el) => {
	// Scans up the DOM tree to see if the element has an <a> tag with an href as
	// an ancestor. Can be used to determine if an event was triggered from
	// within an <a> without actually being triggered by the <a> itself, since an
	// <a> with a <span> inside would trigger the 'click' event on the <span>.
	if (el.tagName === 'A' && el.href) {
		return el;
	}
	if (el.parentElement) {
		return getLink(el.parentElement);
	}
	return null;
};

export const trackSnowplowLinkClicks = (eventName, rpName, storeName) => {
	document.addEventListener('click', (e) => {
		const link = getLink(e.target);
		if (link) {
			trackSnowplowEvent({
				event: eventName,
				data: {
					targetUrl: link.href,
					elementContent: link.innerText,
				},
				sso_session: {
					client: rpName === undefined ? 'account-management' : rpName,
					branding: storeName === undefined ? 'PC™id Account Management' : storeName,
				},
			});
		}
	}, true);
};

export const initTracking = (
	getPageViewPayload,
	flags = { snowplow: false },
	rpName,
	storeName
) => {
	if (global.window) {
		if (flags.snowplow) {
			const { ENVIRONMENT } = window.pcid;
			const snowplowEnvironment = snowplowEnvironmentMap[ENVIRONMENT];
			global.window.analyticsLayer = global.window.analyticsLayer || [];
			initializeSnowplow(snowplowEnvironment, {
				isVerbose: snowplowEnvironment === 'dev',
			});
			trackSnowplowLinkClicks(EventName.LinkClick, rpName, storeName);
		}

		global.window.dataLayer = global.window.dataLayer || [];
		const handleRouteChangeComplete = trackRouteChange(
			getPageViewPayload,
			flags.snowplow
		);
		Router.events.on('routeChangeComplete', handleRouteChangeComplete);
	}
};
