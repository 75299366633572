import React from 'react';
import NextApp from 'next/app';
import Router from 'next/router';
import * as LDClient from 'ldclient-js';
import * as Sentry from '@sentry/react';
import 'proxy-polyfill/proxy.min';
import 'babel-polyfill';
import '@formatjs/intl-locale/polyfill';
import svg4everybody from 'svg4everybody';
import { parse } from 'qs';

import ConfigProvider from '@pcid/components/config-context';
import LocaleProvider from '@pcid/components/locale-provider';
import LoadingPage from '@pcid/components/loading-page';
import { GlobalNotificationContext } from '@pcid/components/state-context';
import { initTracking, trackRouteChange } from '@pcid/analytics/config';
import parseQueryString from '@pcid/utils/parse-query-string';
import noop from '@pcid/utils/noop';
import {
	getRelyingPartyFile,
	getRelyingPartyFromRouter,
	DUMMY_RP,
	relyingPartyDefaults,
	getStoreName,
} from '@pcid/utils/relying-party-utils';
import {
	getRelyingPartyStorage,
	getLoginCtxStorage,
	getCorrelationIdStorage,
	getRelyingPartyTrackerStorage,
	getStateStorage,
} from '@pcid/storage-utils';
import { autoFormatMessage } from '@pcid/string-utils';
import generateCorrelationId from '@pcid/utils/correlation-id';
import { getRequestLocale, decodeState } from '@pcid/request-utils';
import { parseLanguage, localeManager } from '@pcid/locale-utils';
import { logErrorCase } from '@pcid/utils/form-utils';
import hideQueryParams from '@pcid/utils/hide-query-params';

import { getPageViewPayload, trackSnowplowUser } from '../actions/track-analytics';
import '../styles/main.scss';
import { processGetUser, processKmsiLogin, processHttpOnly } from '../actions/login';
import { processLogout } from '../actions/logout';

export class App extends NextApp {
	static async getInitialProps({ ctx }) {
		const locale = getRequestLocale(ctx.req);
		const { loginCtx, state, hidePageView = false } = ctx.query || {};
		return { locale, loginCtx, state: decodeState(state), hidePageView };
	}

	state = {
		metricsID: null,
		status: 'success',
		text: '',
		notificationPosition: 'below',
		showNotification: false,
	}

	async componentDidMount() {
		const { locale, router, state, loginCtx, hidePageView } = this.props;
		const { ENVIRONMENT, GTM, LAUNCH_DARKLY_CLIENT_ID } = window.pcid;
		const { locale: queryLocale } = parse(router.asPath.split('?')[1]) || {};
		const correlationIdStorage = getCorrelationIdStorage();
		const relyingPartyStorage = getRelyingPartyStorage();
		const relyingPartyTrackerStorage = getRelyingPartyTrackerStorage();
		const relyingPartyFromRouter = getRelyingPartyFromRouter(router);
		const relyingParty = state.relyingParty
			|| relyingPartyFromRouter
			|| relyingPartyStorage.get()
			|| DUMMY_RP;

		// If the RP value came from the URL we store it for next time (in case user refreshes the page)
		if (relyingPartyFromRouter || relyingParty === DUMMY_RP || state.relyingParty) {
			relyingPartyStorage.set(relyingParty);
			hideQueryParams('rp');
		} else {
			hideQueryParams('status');
		}

		if (relyingParty && loginCtx) {
			getLoginCtxStorage().setIn(relyingParty, loginCtx);
			getStateStorage().set(state);
		}

		if (!correlationIdStorage.get()) {
			correlationIdStorage.set(generateCorrelationId());
		}

		if (state.relyingPartyTracker) {
			relyingPartyTrackerStorage.set(state.relyingPartyTracker);
		}

		const featureFlags = (await import(`../../flags/${ENVIRONMENT}.js`)).default;

		// get feature flag values from Launch Darkly
		this.ldclient = LDClient.initialize(
			LAUNCH_DARKLY_CLIENT_ID,
			{ key: relyingParty || 'GENERIC_DO_NOT_TARGET' }
		);

		this.ldclient.waitForInitialization()
			.then(() => {
				// get maintenanceMessage flag value from launch darkly
				const maintenanceMessage = this.ldclient.variation('maintenance-message', false);

				// show maintenanceMessage if maintenanceMessage flag value from launch darkly
				// is true or the flag from local FF config files is true
				featureFlags.maintenanceMessage = maintenanceMessage || featureFlags.maintenanceMessage;
			})
			.catch(noop)
			.finally(() => {
				this.setState({ featureFlags }, () => {
					this.updateConfig(
						relyingParty,
						queryLocale || state.locale || locale,
						this.initialLanguage
					);
				});
			});

		if (router.pathname === '/login' && state.intent === 'register') {
			Router.replace('/create-account').then(async () => {
				this.initMetrics(GTM, { snowplow: featureFlags.snowplow });
				trackRouteChange(
					getPageViewPayload, featureFlags.snowplow
				)(router.asPath, { hidePageView });
			});
		} else {
			this.initMetrics(GTM, { snowplow: featureFlags.snowplow });
			trackRouteChange(getPageViewPayload, featureFlags.snowplow)(router.asPath, { hidePageView });
		}
		this.initialLanguage = parseLanguage(
			queryLocale
			|| state.language
			|| localeManager.getLocale()
			|| locale
			|| 'en'
		);

		if (this.isKmsiContinueAsLogin()) {
			Router.replace('/continue', null, { shallow: true });
		} else if (this.isKmsiLogin()) {
			// getting user data from cookies
			// if cookies are not present, it proceeds to normal login screen
			processGetUser().then((user) => {
				processKmsiLogin({ loginCtx, user });
			});
		}

		this.logoutFunction(router);

		// SVG Browser Compatibility
		svg4everybody();

		// If the user is coming from a KMSI login, we need not process the login as the value
		// will be in the cookie, here we are only populating the localstorage data.
		// passing loginCtx as a param to processHttpOnly for validation purpose only
		processHttpOnly({ loginCtx });

		router.events.on('routeChangeComplete', () => { window.scrollTo(0, 0); });

		router.events.on('routeChangeStart', (url) => {
			const routeTrace = Sentry.startTransaction({ name: url });
			router.events.on('routeChangeComplete', () => { routeTrace.finish(); });
		});
	}

	logoutFunction = (router) => {
		if (router.pathname === '/logout') {
			try {
				const query = parseQueryString(router.asPath);
				const URIdecodedRequest = decodeURIComponent(query.state);
				const base64Decoded = Buffer.from(URIdecodedRequest, 'base64').toString('utf-8');
				const stateBody = JSON.parse(base64Decoded);
				const requestBody = {
					redirectUri: stateBody.redirectUri,
					refreshToken: stateBody.refreshToken,
					idToken: stateBody.idToken,
					clientId: stateBody.clientId,
					clientSecret: stateBody.clientSecret,
					kmsiLogout: stateBody.kmsiLogout,
				};
				processLogout({ requestBody });
			} catch (err) {
				logErrorCase(err);	// log to client-logging-service
			}
		}
	}

	changeStatus = ({ newStatus, newText, newPosition }) => {
		this.setState({
			showNotification: true,
			text: newText,
			status: newStatus,
			notificationPosition: newPosition,
		});
	}

	updateConfig = async (
		relyingParty = getRelyingPartyStorage().get(),
		locale = localeManager.getLocale(),
		language = localeManager.getLanguage()
	) => {
		const { state: { loginMessage } } = this.props;
		const fileName = getRelyingPartyFile(relyingParty);
		let rpConfig;
		rpConfig = fileName ? (await import(`../../config/${fileName}.js`)).default : relyingPartyDefaults;
		rpConfig = typeof rpConfig === 'object' ? rpConfig : rpConfig({ locale, language });
		rpConfig.customLoginMessage = rpConfig.loginMessages && rpConfig.loginMessages[loginMessage];
		this.setState({ rpConfig, relyingParty });
		if (this.state.featureFlags && this.state.featureFlags.maintenanceMessage) {
			this.changeStatus({
				newStatus: 'maintenance',
				newText: this.state.featureFlags.maintenanceMessageString,
				newPosition: 'above',
			});
		}
	}

	initMetrics(ID, flags) {
		const rpName = getRelyingPartyStorage().get();
		const storeNameRaw = getStoreName(rpName);
		const storeName = autoFormatMessage(storeNameRaw);
		initTracking(getPageViewPayload, flags, rpName, storeName);
		trackSnowplowUser();
		this.setState({ metricsID: ID });
	}

	isKmsiLogin = () => {
		const { router, state } = this.props;
		const kmsiEnabled = state.keepMeSignedIn && state.clientId && state.intent !== 'register';
		return router.pathname === '/login' && kmsiEnabled;
	}

	isKmsiContinueAsLogin = () => {
		const { router, state } = this.props;
		const kmsiEnabled = state.keepMeSignedIn && state.clientId && state.showIsThisYouPrompt && state.intent !== 'register';
		return router.pathname === '/login' && kmsiEnabled;
	}

	render() {
		const { Component, pageProps, state: { intent, language } } = this.props;
		const {
			metricsID,
			featureFlags,
			rpConfig,
			relyingParty,
			showNotification,
			notificationPosition,
			status,
			text,
		} = this.state;

		if (this.isKmsiLogin()) {
			/* pass in initLanguage from state here to ensure the loading page
			* in the very beginning can have proper language config
			*/
			return <LoadingPage initLanguage={language} />;
		}

		return metricsID && featureFlags && rpConfig
			? (
				<Sentry.ErrorBoundary>
					<LocaleProvider
						initialLanguage={this.initialLanguage}
						updateConfig={this.updateConfig}
					>
						<ConfigProvider
							featureFlags={featureFlags}
							relyingParty={relyingParty}
							rpConfig={rpConfig}
							updateConfig={this.updateConfig}
						>
							<GlobalNotificationContext.Provider
								value={{
									changeStatus: this.changeStatus,
									showNotification,
									notificationPosition,
									status,
									text,
									dismissNotification: () => this.setState({ showNotification: false }),
								}}
							>
								<Component
									intent={intent}
									{...pageProps}
								/>
							</GlobalNotificationContext.Provider>
						</ConfigProvider>
					</LocaleProvider>
				</Sentry.ErrorBoundary>
			)
			/* pass in initLanguage from state here to ensure the loading page
			* in the very beginning can have proper language config
			*/
			: <LoadingPage initLanguage={language} />;
	}
}

export default Sentry.withProfiler(App);
