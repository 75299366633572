import { EmailValidator } from 'commons-validator-js';

const emailValidator = new EmailValidator();

/**
 * Simple curried function for creating a series of regex testers.
 *
 * @example
 *
 * ```
 * const isNumber = testAgainstRegex(/\d/);
 * isNumber('1'); // true
 * isNumber('abc'); // false
 * ```
 *
 * @param {RegExp} regex - The regex to test agains
 * @returns {Function} doTest - A function that will test the given string
 */
function testAgainstRegex(regex) {
	return function doTest(subject) {
		return regex.test(subject);
	};
}

// Create a list of regexes to test against - do this once instead of
// instantiating a new regex on every call to the validator.
const checkAddressFormat = testAgainstRegex(
	/^([a-z0-9!#$%&*+=^_{|}-]+)(.([a-z0-9!#$%&*+/=?^_{|}-]+))*@([a-z0-9][a-z0-9-]{0,62})(.([a-z0-9][a-z0-9-]{0,62}))*.[a-z]{2,11}$/i
);
const checkAddressLength = testAgainstRegex(/^.{1,64}@.{1,190}$/);
const regexTesters = [
	checkAddressFormat,
	checkAddressLength,
];

/**
 * Email validation as per the PCid v4 middleware (and presumably IDCS).
 *
 * The RegEx came from the middleware team, and the `commons-validator-js` lib
 * is a JavaScript implementation of the open source library that Apache
 * maintains called `commons-validator` (which is written in Java).
 *
 * This function is as close as we can get to running the same validation check
 * as downstream services on all front-end pages.
 *
 * @param  {String} address - The address to validate
 * @returns {Boolean} - Whether or not it is valid
 */
export default function isEmail(address) {
	return (
		regexTesters.every((tester) => tester(address))
		&& emailValidator.isValid(address)
	);
}
