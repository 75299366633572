import React from 'react';

const LoadingAnimation = () => (
	<div className="loading-animation">
		<i className="loading-animation__dot" />
		<i className="loading-animation__dot" />
		<i className="loading-animation__dot" />
		<i className="loading-animation__dot" />
		<i className="loading-animation__dot" />
	</div>
);

export default LoadingAnimation;
