import React from 'react';
import Router from 'next/router';

import LoadingPage from '@pcid/components/loading-page';

export default class extends React.Component {
	componentDidMount() {
		Router.replace('/login', null, { shallow: true });
	}

	render() {
		return <LoadingPage />;
	}
}
