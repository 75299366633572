/**
 * This file is meant to hold any utilities for interacting with client-side storage, be it cookies,
 * localStorage, or sessionStorage. Any individual components that need to interact with the store
 * can create an instance of storage to allow for a separate mgmt of storage. A default storage is
 * created to start for quick usuage.
 */
const StorageTypes = {
	LOCAL: 'LOCAL',
	SESSION: 'SESSION',
};

class DummyStore {
	getItem(name) {
		return this[name];
	}

	setItem(name, value) {
		this[name] = value;
	}

	removeItem(name) {
		delete this[name];
	}
}

class Storage {
	constructor(prefix, name, type = StorageTypes.LOCAL) {
		const storageMap = {
			[StorageTypes.LOCAL]: global.window ? window.localStorage : new DummyStore(),
			[StorageTypes.SESSION]: global.window ? window.sessionStorage : new DummyStore(),
		};
		this.name = `${prefix}.${name}`;
		this.type = type;
		this.store = storageMap[type];
	}

	get() {
		let item;
		try {
			item = JSON.parse(this.store.getItem(this.name));
		} catch (err) {
			// Remove if parse fails.
			this.remove();
			return null;
		}
		return item;
	}

	set(value) {
		const str = JSON.stringify(value);
		if (str) {
			try {
				JSON.parse(str);
				this.store.setItem(this.name, str);
			} catch (err) {
				throw Error(`Error setting ${value} in storage ${this.name}. \n ${err}`);
			}
		} else {
			this.remove();
		}
		return this;
	}

	remove() {
		try {
			this.store.removeItem(this.name);
		} catch (err) {
			throw Error(`Error removing storage ${this.name}. \n ${err}`);
		}
		return this;
	}

	getIn(key) {
		const stored = this.get();
		return stored && stored[key];
	}

	setIn(key, value) {
		this.set({ ...this.get(), [key]: value });
		return this;
	}

	removeIn(key) {
		const { [key]: forget, ...rest } = this.get() || {};
		if (Object.keys(rest).length) {
			this.set(rest);
		} else {
			this.remove();
		}
		return this;
	}
}
class StorageManager {
	constructor(prefix) {
		this.prefix = prefix;
		this.managedStorage = {};
	}

	createOrFetchStorage(name, type) {
		if (this.managedStorage[name] && type === this.managedStorage[name].type) {
			return this.managedStorage[name];
		}
		try {
			const newStorage = new Storage(this.prefix, name, type);
			this.managedStorage[name] = newStorage;
			return newStorage;
		} catch (err) {
			throw Error(`Error creating storage ${this.name}. \n ${err}`);
		}
	}

	clearSession() {
		Object.keys(this.managedStorage).forEach((name) => {
			const storage = this.managedStorage[name];
			if (storage.type === StorageTypes.SESSION) {
				storage.remove();
			}
		});
		return this;
	}
}
const storageManager = new StorageManager('accounts.pcid');

module.exports = {
	StorageTypes,
	DummyStore,
	Storage,
	StorageManager,
	storageManager,
};
