import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export const bannersPath = '/svg/sprite-banners.svg';
export const mainPath = '/svg/sprite-icons.svg';

/**
 * Render an SVG icon from the sprite sheet.
 *
 * Icon source files are in `app/svgs/`.
 *
 * UI group: symbol value is the icon's file name (minus .svg)
 * BRAND group: symbol value is the icon's file name (minus .svg)
 * GRAPHICS group: symbol value is the the file name _before_ `-color.svg`. Specify
 * the background color you want as a prop, or leave empty for red (the default)
 *
 * Examples:
 *
 * <Sprite group="ui" symbol="email" />
 * <Sprite group="ui" symbol="error" />
 * <Sprite group="brand" symbol="logo-pc-optimum" />
 * <Sprite group="graphics" symbol="calculator" color="white" />
 * <Sprite group="graphics" symbol="optimum-card" color="red" background="pink" />
 *
 * Note: we set `focusable="false"` in the SVG because IE/Edge
 * by default will put SVGs in the tabbing order unless we do this
 * (it looks like a bug). Source: https://github.com/angular/material/issues/7250
 *
 */
class Sprite extends React.Component {
	static propTypes = {
		symbol: PropTypes.string.isRequired,
		group: PropTypes.oneOf([
			'brand',
			'banners',
			'icons',
		]).isRequired,
		className: PropTypes.string,
		focusable: PropTypes.bool,
		ariaHidden: PropTypes.bool,
		role: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		focusable: false,
		ariaHidden: false,
		role: 'presentation',
	}

	// Once a sprite is rendered we _do not_ need to re-render it. Sprites get called A LOT,
	// so this has significant performance implications. There are some cases where we are
	// conditionally switching the symbol that is being rendered though (never the group), so
	// we should only rerender if symbol has changed.
	// In emailVerification we are switching the className as well so we need to update that.
	shouldComponentUpdate(nextProps) {
		const { symbol, className } = this.props;
		return (nextProps.symbol !== symbol) || (nextProps.className !== className);
	}

	render() {
		const {
			group,
			symbol,
			className,
			focusable = false,
			ariaHidden = true,
			...rest
		} = this.props;

		const fragment = `${symbol}`;
		const path = group === 'banners' ? bannersPath : mainPath;
		const symbolClasses = classNames(
			'sprite',
			className
		);

		/**
		* Note the {' '} markup around the <use> tag.  This fixes an issue with Safari
		* 10 where SVGs reset the tabbing of the page to the beginning of the document.
		* Please do not remove.
		*
		* Source: https://bugs.webkit.org/show_bug.cgi?id=162866
		*/

		return (
			<svg
				aria-hidden={ariaHidden}
				focusable={focusable.toString()}
				className={symbolClasses}
				{...rest}
			>
				{' '}<use xlinkHref={`${path}#${fragment}`} />{' '}
			</svg>
		);
	}
}

export default Sprite;
