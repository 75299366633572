// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { ExtraErrorData } from '@sentry/integrations';
import * as TracingIntegrations from '@sentry/tracing';

const { SENTRY_DSN, ENVIRONMENT, RELEASE } = window.pcid;

TracingIntegrations.addExtensionMethods();

Sentry.init({
	dsn: SENTRY_DSN,
	// Adjust this value in production, or use tracesSampler for greater control
	tracesSampleRate: 1.0,
	environment: ENVIRONMENT,
	release: RELEASE,
	integrations: [
		new ExtraErrorData({ depth: 50 }),
		new TracingIntegrations.BrowserTracing({
			tracingOrigins: [
				'identity.oraclecloud.com',
				'accounts-pcid-dev.loblaw.digital',
				'accounts-lower.pcid.ca',
				'accounts.pcid.ca',
				'myaccount-pcid-dev.netlify.app',
				'myaccount-lower.netlify.app',
				'myaccount.pcid.ca',
			],
		}),
	],
});
