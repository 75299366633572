import {
	getCorrelationIdStorage,
	getRelyingPartyStorage,
	getRelyingPartyTrackerStorage,
} from '@pcid/storage-utils';
import { RequestBuilder, getSentryTrace } from '@pcid/request-utils';

const getRelyingPartyHeaders = () => ({ 'Relying-Party': getRelyingPartyStorage().get() });
const getCorrelationIdHeaders = () => ({ 'x-correlation-id': getCorrelationIdStorage().get() });
const getRelyingPartyTrackerHeaders = () => {
	const rpTracker = getRelyingPartyTrackerStorage().get();
	if (!rpTracker) return {};
	return { 'relying-party-tracker': rpTracker };
};

export default class ClientRequestBuilder extends RequestBuilder {
	constructor(rootUrl) {
		super(rootUrl);
		this.headers = {
			...getRelyingPartyHeaders(),
			...getCorrelationIdHeaders(),
			...getRelyingPartyTrackerHeaders(),
			'sentry-trace': getSentryTrace(),
		};
	}

	withHeaders(headers) {
		return super.withHeaders({
			...getRelyingPartyHeaders(),
			...getCorrelationIdHeaders(),
			...getRelyingPartyTrackerHeaders(),
			...headers,
		});
	}
}
