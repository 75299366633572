import * as Sentry from '@sentry/browser';
import { FormError, logErrorCase } from '@pcid/utils/form-utils';
import oops from '@pcid/utils/oops';
import { errorCodes } from '@pcid/utils/validation';

import { minimumScore, Integrity } from '@pcid/validate-password';

export default function handleError(err) {
	const { response, request } = err;
	let formError = {};
	if (response) {
		const config = response.config && JSON.parse(response.config.data);
		const { email: requestEmail, rememberMe, sso } = config;
		const { data: { code, message, error, errorDescription } } = response;
		const logData = {
			req: {
				data: { email: requestEmail, rememberMe, sso },
				headers: response.config.headers,
				method: response.config.method,
				url: response.config.url,
			},
			res: {
				headers: response.headers,
				status: response.status,
				statusText: response.statusText,
			},
			err: {
				message: err?.message,
				stack: err?.stack,
			},
		};

		formError = {
			error: error || code,
			errorDescription: errorDescription || message,
			...logData,
		};

		if (response.status === 429) {
			formError.error = 'SHAPE-429';
		} else if (code) {
		//  Deprecated IDCS 'code/message' error we log to the Middleware Cient Logging Service
		// This block should be removed once we can confirm we no longer receive these errors
			logErrorCase(logData);
			switch (code) {
				case 'PASSWORD_POLICY_VIOLATION': {
					// Here we turn the middleware response into a field-level error, and update the password
					// strength meter. Note that unless you want to re-map the 'newPassword' field name after
					// you process this error, your password field has to be named 'newPassword', or it won't
					// pick up this error
					formError.fieldErrors = { newPassword: errorCodes.weakPassword };
					formError.fieldValues = {
						passwordStrength: {
							policyScore: minimumScore - 1,
							integrity: Integrity.BREACHED,
						},
					};
					break;
				}
				case 'AUTH-1027':
				case 'AUTH-1010':
				case 'AUTH-3002':
					return oops.locked(new FormError(formError));
				case 'AUTH-3003':
					return oops.deactivated();
				case 'DUPLICATE_EMAIL_ID': {
					const { email } = JSON.parse(response.config.data);
					formError.values = { email };
					break;
				}
				default: {
					break;
				}
			}
		}
	} else if (request) {
		formError.error = 'OFFLINE';
	} else if (typeof err === 'string') {
		formError.error = err;
	} else {
		formError = { error: err };
	}

	formError = new FormError(formError);
	Sentry.captureException(err, { extra: { formError, response, request } });
	throw formError;
}

export const consoleError = (err) => {
	// eslint-disable-next-line no-console
	console.error(err._trace.join());
};
