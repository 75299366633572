const { stringify } = require('qs');
const { parseLanguage, localeManager } = require('@pcid/locale-utils');

function getLinks(locale = localeManager.getLocale(), rp, termsOrigin) {
	const language = parseLanguage(locale);
	const communitiesQuery = stringify({ language: parseLanguage(locale) === 'fr' ? 'fr' : 'en_US', rp });
	return {
		about: `https://loblaw.force.com/pcid/s/about-us?${communitiesQuery}`,
		help: `https://loblaw.force.com/pcid/s/?${communitiesQuery}`,
		terms: termsOrigin ? `${termsOrigin}/terms?locale=${locale}` : `/terms?locale=${locale}`,
		privacy: `https://www.loblaw.ca/${language}/privacy.html`,
	};
}

module.exports = getLinks;
