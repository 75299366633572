/**
 * Naive query string parser to accommodate reading query param values without decoding
 * them in static builds.
 *
 * Pass in a queryString or the result of calling `router.asPath`, and get back the query params
 * as a POJO.
 *
 * @param {String} queryString - A query string from a URL
 * @returns {Object} - The query string k/v pairs as an object
 */
function parseQueryString(queryString = '') {
	const toParse = queryString.includes('?') ? queryString.split('?')[1] : queryString;
	return toParse.split('&').reduce((params, next) => {
		const [key, value] = next.split('=');
		return Object.assign(params, { [key]: value });
	}, {});
}

export default parseQueryString;
