import { isInt, isEmpty, isLength } from 'validator';
import checkIntegrity, { minimumScore } from '@pcid/validate-password';

import isEmail from './is-email';

export const errorCodes = {
	invalidChars: 'common.errors.invalidChars',
	invalidEmail: 'common.errors.invalidEmail',
	codeRequired: 'common.errors.codeRequired',
	numberRequired: 'common.errors.numberRequired',
	emailRequired: 'common.errors.emailRequired',
	passwordRequired: 'common.errors.passwordRequired',
	sixDigits: 'common.errors.sixDigits',
	doesNotExist: 'common.errors.doesNotExist',
	doesNotMatch: 'common.errors.doesNotMatch',
	emptyFirstName: 'common.errors.emptyFirstName',
	emptyLastName: 'common.errors.emptyLastName',
	illegalCharacter: 'common.errors.illegalCharacter',
	nameTooLong: 'common.warnings.nameTooLong',
	emailDoesNotMatch: 'common.errors.emailDoesNotMatch',
	passwordDoesNotMatch: 'common.errors.passwordDoesNotMatch',
	confirmPasswordRequired: 'common.errors.confirmPasswordRequired',
	wrongLength: 'common.errors.wrongLength',
	wrongNumberLength: 'common.errors.wrongNumberLength',
	tooLong: 'common.errors.tooLong',
	tooShort: 'common.errors.tooShort',
	tooLongOrShort: 'common.errors.tooLongOrShort',
	weakPassword: 'common.errors.weakPassword',
	privacyPolicyRequired: 'common.errors.privacyPolicyRequired',
	termsAndConditionsRequired: 'common.errors.termsAndConditionsRequired',
	emailExists: {
		id: 'common.errors.emailExists',
		theme: 'warning',
	},
	shapeError: 'form.error.shape429',
	shapeEmail: 'form.error.shape429.email',
};

// This is a utility that takes a bunch of validators and "or"'s them together into one big
// "composed" validator, expecting the pattern that a validator returns null if the value
// passes, or a truthy error code if it fails
export const composeValidator = (...validators) => (...args) => validators
	.reduce((error, validator) => error || validator(...args), null);

// withErrorMessage wraps a validator with an override message (to handle cases where you
// might want a more specific error message to appear on the invalid field, like "Email is
// required" vs. "This field is required")
export const withErrorMessage = (validator, message) => (...args) => validator(...args) && message;

// The `extend` util wraps a validator and returns a new validator, extending the wrapped
// validator in two ways:
//	1.	Passes all falsy and empty values (unless the "optional" config is explicitly set to
//		false) — This is to allow the base validator to focus on testing format, pattern, length,
//		etc., without worrying about optional/required logic (which will be tested by the
//		`toExist` validator anyway);
//	2.	Attaches an `else` method onto the base validator, which provides a more readable way
//		to use withErrorMessage (e.g. `toExist.else('my.custom.errorCode')` vs.
//		`withErrorMessage(toExist, 'my.custom.errorCode')`)
export const extend = (validator, { optional = true } = {}) => {
	let newValidator = validator;

	if (optional) {
		// This is to allow validation on optional fields
		newValidator = (value, ...args) => (
			!value || isEmpty(`${value}`)
				? null
				: validator(value, ...args)
		);
	}

	// This is to allow the error message to be overridden
	// eslint-disable-next-line no-param-reassign
	newValidator.else = (message) => withErrorMessage(validator, message);
	return newValidator;
};
export const toBeAnEmail = extend((email) => (isEmail(email) ? null : errorCodes.invalidEmail));
export const toBeAName = extend(
	(name) => {
		if (!isLength(name, { min: 1, max: 150 })) {
			return errorCodes.nameTooLong;
		}
		if (!/^[-\s]*[a-zA-Z\u00C0-\u017F '-]+$/.test(name)) {
			return errorCodes.illegalCharacter;
		}
		return null;
	},
	{ optional: false }
);

export const toBeANumber = extend((number) => (isInt(number) ? null : errorCodes.invalidChars));
export const toExist = extend(
	(value) => (isEmpty(value) ? errorCodes.doesNotExist : null),
	// Here, we explicitly set `extend`'s "optional" config to false, because `toExist` is always
	// used to test non-optional fields
	{ optional: false }
);

export const toBeChecked = extend((value) => (value ? null : errorCodes.doesNotExist));

export const toHaveMinLength = (min) => extend((value) => (
	value.toString().length >= min
		? null
		: { id: errorCodes.tooShort, values: { min } }
));
export const toHaveMaxLength = (max) => extend((value) => (
	value.toString().length <= max
		? null
		: { id: errorCodes.tooLong, values: { max } }
));
export const toHaveExactLength = (
	length,
	errorMessage = errorCodes.wrongLength
) => extend((value) => (
	value.toString().length === length
		? null
		: { id: errorMessage, values: { length } }
));
export const toHaveLengthInRange = (min, max) => extend((
	withErrorMessage(composeValidator(toHaveMinLength(min), toHaveMaxLength(max)), {
		id: errorCodes.tooLongOrShort,
		values: { min, max },
	})
));
export const toMatch = (match = '') => {
	// Extract the test out of the match (if it's a regex), or default to an equality test
	const _test = (value) => (
		typeof match.test === 'function'
			? match.test(value)
			: match === value
	);

	return extend((value) => (_test(value) ? null : errorCodes.doesNotMatch));
};

export const toMeetPasswordPolicy = (score) => () => (
	score >= minimumScore
		? null
		: errorCodes.weakPassword
);

// This is a wrapper around composeValidators, so we can write validators like sentences.
// Also, to avoid clashing with, jest's `expect` and node's `require`, I'm calling it
// "speck", a la Ricky Ricardo: https://www.youtube.com/watch?v=MMj9Fm4SBq8
const speck = (value, ...validators) => composeValidator(...validators)(value);

export default speck;

export const getPasswordValidateProps = ({ zxcvbn }) => ({
	validate: (password = '', { setMeta, formik: { setFieldValue } }) => {
		const { score: policyScore } = zxcvbn(password);
		const syncError = speck(
			password,
			toExist.else(errorCodes.passwordRequired),
			toHaveMinLength(10),
			toMeetPasswordPolicy(policyScore)
		);

		// Side effects
		if (syncError) {
			setFieldValue('passwordStrength', { policyScore: Math.min(policyScore, minimumScore - 1) });
		} else {
			setMeta({ policyScore });
		}

		return syncError;
	},
	asyncValidate: (password, {
		getMeta,
		setMeta,
		formik: { values },
	}) => checkIntegrity(values.email, password)
		.then(({ integrity, policyScore = getMeta().policyScore }) => {
			setMeta({ policyScore, integrity });
			return toMeetPasswordPolicy(policyScore)();
		}),
	onAsyncValidateComplete: (_, {
		getMeta,
		formik: { setFieldValue },
	}) => { setFieldValue('passwordStrength', getMeta()); },
});
