import Router from 'next/router';
import { stringify } from 'qs';

import noop from '@pcid/utils/noop';
import oops from '@pcid/utils/oops';
import handleError from '@pcid/actions/handle-error';
import { hashEmail } from '@pcid/request-utils';
import { generateFingerprint } from '@pcid/utils/fingerprint-utils';
import RequestBuilder from '@pcid/client-request-builder';
import {
	getEmailStorage,
	getTrustTokenStorage,
	getRequestStateStorage,
	getRequestStateLoginStorage,
	getRememberedUserStorage,
	encodeString,
	getAuthFactorStorage,
	getCaslStorage,
	getKmsiTokenStorage,
	getKmsiEmailStorage,
	getStateStorage,
} from '@pcid/storage-utils';
import {
	trackLoginSuccess,
	trackLoginFailure,
	trackMFALoginSuccess,
	trackMFALoginFailure,
	trackMFAResendCode,
	trackMFASetupSuccess,
	trackGenericException,
	trackGenericExceptionSnowplow,
	trackKmsiLoginFailure,
	trackLoginSubmit,
} from '../track-analytics';

// Points localhost requests to Middleware for testing
export const getMiddlewareUrl = () => {
	if (window.pcid.ENVIRONMENT === 'local') {
		// If running locally and proxy is enabled, proxy requests to embedded proxy server,
		// otherwise send requests to middleware URL
		return window.pcid.MIDDLEWARE_PROXY === 'true' ? `${window.location.origin}/proxy` : window.pcid.MIDDLEWARE_URL;
	}
	return window.location.origin;
};

export const getOriginUrl = () => window.location.origin;

export const finishLogin = ({
	url: redirectURL,
	lastOp = 'login',
	track = noop,
}) => {
	const casl = getCaslStorage().get();
	return Promise.resolve()
		.then(track)
		.then(() => Router.replace(
			`/login/success?${stringify({ redirectURL, lastOp, casl })}`,
			'/login/success'
		));
};

export const isExpiredRequestStateError = (id = '') => [
	'AUTH-1117',
	'AUTH-1120',
	'INVALID_REQUEST_STATE',
].includes(id);

export const storeAuthFactors = ({
	email,
	requestState,
	EMAIL,
	SMS,
	nextAuthFactors,
}) => {
	getRequestStateStorage().set(requestState); // update session storage with new request state
	getRequestStateLoginStorage().set(requestState); // for editing mandatory 2FA methods
	let authFactor;
	let allAuthFactors = null;
	if (EMAIL && SMS) {
		authFactor = EMAIL.preferred ? { ...EMAIL, method: 'EMAIL', email } : { ...SMS, method: 'SMS' };
		allAuthFactors = [{ ...EMAIL, method: 'EMAIL', email }, { ...SMS, method: 'SMS' }];
	} else if (EMAIL) {
		authFactor = { ...EMAIL, method: 'EMAIL', email };
	} else {
		authFactor = { ...SMS, method: 'SMS' };
	}
	authFactor = { ...authFactor, nextAuthFactors };
	if (allAuthFactors) authFactor.allAuthFactors = allAuthFactors;
	getAuthFactorStorage().set(authFactor);
};

export const processLogin = (formData = {}) => {
	trackLoginSubmit();
	const { email = '', rememberMe, mandatory2fa } = formData;
	const hashedEmail = hashEmail(email);
	const encodedEmail = encodeString(email);
	const trustToken = getTrustTokenStorage().getIn(hashedEmail);
	getEmailStorage().set(email);
	const rememberedUserStorage = getRememberedUserStorage();

	const deviceDetails = generateFingerprint();
	const rpState = getStateStorage().get() || {};
	const keepMeSignedIn = rpState.clientId && rpState.keepMeSignedIn;

	if (keepMeSignedIn) {
		getKmsiEmailStorage().set(hashedEmail);
	}

	// Store email address if user wants to be remembered.
	if (rememberMe) {
		rememberedUserStorage.set(encodedEmail);
	} else if (rememberedUserStorage.get() === encodedEmail) {
		rememberedUserStorage.remove();
	}

	return new RequestBuilder(getMiddlewareUrl())
		.withData({
			...formData,
			trustToken,
			deviceDetails,
			keepMeSignedIn,
			hashedEmail,
			encodedEmail,
		})
		.post('/login')
		.then(({ data }) => {
			const {
				url,
				requestState,
				trustToken: newTrustToken,
				EMAIL,
				SMS,
				nextAuthFactors,
				mfaEnrolmentRequired,
				kmsiToken,
			} = data;

			if (newTrustToken) {
				getTrustTokenStorage().setIn(hashedEmail, newTrustToken);
			}
			if (kmsiToken) {
				getKmsiTokenStorage().set(kmsiToken);
			}
			if (requestState) { // response has a request state MFA required
				storeAuthFactors({ email, requestState, EMAIL, SMS, nextAuthFactors });

				return mfaEnrolmentRequired && mandatory2fa
					? Router.replace('/login/protect')
					: Router.replace('/login/verification?showBackButton=false');
			} // response isn't a url (MFA required)
			return finishLogin({ url, track: trackLoginSuccess });
		})
		.catch(handleError)
		.catch((error) => {
			if (isExpiredRequestStateError(error)) return oops.expired();
			if (error.tags.errorCode === 'PASSWORD_POLICY_VIOLATION') return Router.push('/login/update-security');
			throw error;
		})
		.catch(trackLoginFailure);
};

export const processMFAChallenge = (formData = {}) => {
	const rpState = getStateStorage().get() || {};
	const keepMeSignedIn = rpState.clientId && rpState.keepMeSignedIn;
	const email = getEmailStorage().get();
	let hashedEmail;

	if (email) {
		hashedEmail = hashEmail(email);
	} else if (keepMeSignedIn) {
		hashedEmail = getKmsiEmailStorage().get();
	}

	return new RequestBuilder(getMiddlewareUrl())
		.withData(({
			...formData,
			hashedEmail,
		}))
		.post('/mfa-challenge')
		.then(({ data }) => {
			const { url, trustToken, requestState, kmsiToken } = data;
			if (kmsiToken) {
				getKmsiTokenStorage().set(kmsiToken);
			}
			if (trustToken) {
				getTrustTokenStorage().setIn(hashedEmail, trustToken);
			}
			if (requestState) {
				getRequestStateStorage().set(requestState);
			}
			return finishLogin({ url, track: trackMFALoginSuccess.withMeta(formData) });
		})
		.catch(handleError)
		.catch(trackMFALoginFailure.withMeta(formData));
};

export const processMFAResend = (payload, opts) => new RequestBuilder(getMiddlewareUrl())
	.withData(payload)
	.post('/mfa-resend')
	.then(({ data }) => {
		const { requestState } = data;
		if (requestState) {
			getRequestStateStorage().set(requestState);
		}
		if (data.status !== 'success') {
			throw new Error();
		}
	})
	.then(() => {
		if (opts.sendTrack) {
			trackMFAResendCode(payload);
		}
	})
	.catch(handleError)
	.catch(trackGenericException);

// userData refers to both email and phoneNumber.
// userData will be undefined in an email flow.
// userData will be { phoneNumber: xxx } in an sms flow.
export const processMandatoryMfaEnrollment = (userData) => new RequestBuilder(getMiddlewareUrl())
	.withData({
		requestState: getRequestStateLoginStorage().get(),
		...userData,
	})
	.post('/authentication/mfa/enrolment')
	.then(({ data }) => {
		const { requestState } = data;
		if (requestState) {
			getRequestStateStorage().set(requestState);
		}
	})
	.catch(handleError)
	.catch(trackGenericException);

export const processMandatoryMFAChallenge = ({ code }) => new RequestBuilder(getMiddlewareUrl())
	.withData({
		requestState: getRequestStateStorage().get(),
		otpCode: code,
	})
	.patch('/authentication/mfa/challenge')
	.then(({ data }) => {
		const { redirectUri } = data;
		return finishLogin({
			url: redirectUri,
			track: trackMFASetupSuccess.withMeta({
				authFactor: getAuthFactorStorage().get().method,
			}),
		});
	})
	.catch(handleError)
	.catch(trackMFALoginFailure.withMeta({
		authFactor: getAuthFactorStorage().get().method,
	}));

export const processMandatoryMFAResend = () => {
	const requestStateStorage = getRequestStateStorage();
	const requestState = requestStateStorage.get();
	return new RequestBuilder(getMiddlewareUrl())
		.withData({ requestState })
		.post('/authentication/mfa/challenge')
		.then(({ data: { requestState: newRequestState } }) => {
			if (newRequestState) {
				requestStateStorage.set(newRequestState);
			}
		})
		.catch(handleError)
		.catch(trackGenericException);
};

export const handleKmsiLoginFailure = (trackKmsiLoginFailureFlag = true) => {
	if (trackKmsiLoginFailureFlag) trackKmsiLoginFailure();
	const kmsiToken = getKmsiTokenStorage().get();
	getKmsiTokenStorage().remove();
	getKmsiEmailStorage().remove();
	// During SSO Bronze/Continue As login, we will check if there is an existing token (meaning
	// that they are a returning user) before sending them to the login screen with an error message.
	if (kmsiToken) {
		Router.replace('/login?ssoError=true', null, { shallow: true });
	} else {
		// This is where we should handle the redirectWithoutAuth functionality, once
		// IDCS can return a redirectURL for a failed KMSI auth call.
		Router.replace('/login', null, { shallow: true });
	}
};

export const processGetUser = () => new RequestBuilder(getOriginUrl())
	.get('/user')
	.then(({ data: user }) => {
		const { email, checkCookieFlag } = user;
		if (email && checkCookieFlag) {
			getKmsiEmailStorage().set(hashEmail(email));
			return user;
		}
		throw new Error(`invalid user object: ${JSON.stringify(user)}`);
	})
	.catch(trackGenericException)
	.catch(trackGenericExceptionSnowplow)
	.catch(() => handleKmsiLoginFailure(false));

export const processKmsiLogin = ({ loginCtx, user = null }) => {
	// getting kmsi data from cookies
	const hashedKmsiEmail = hashEmail(user?.email) || getKmsiEmailStorage().get();
	getEmailStorage().set(user?.email);

	return new RequestBuilder(getMiddlewareUrl())
		.withData({
			kmsiToken: getKmsiTokenStorage().get(),
			clientId: getStateStorage().get().clientId,
			loginCtx,
			trustToken: getTrustTokenStorage().getIn(hashedKmsiEmail),
			hashedEmail: hashedKmsiEmail,
			hashedKmsiEmail,
		})
		.post('/login')
		.then(({
			data: {
				url,
				kmsiToken,
				requestState,
				trustToken,
				nextAuthFactors,
				mfaEnrolmentRequired,
				EMAIL,
				SMS,
			},
		}) => {
			if (kmsiToken) {
				getKmsiTokenStorage().set(kmsiToken);
			}
			if (trustToken) {
				getTrustTokenStorage().setIn(hashedKmsiEmail, trustToken);
			}
			// An HTTP 200 and { nextAuthFactors: ["USERNAME_PASSWORD"] } is the
			// expected response from IDCS when the KMSI token is invalid.
			if (nextAuthFactors?.includes('USERNAME_PASSWORD')) {
				return handleKmsiLoginFailure(true);
			}

			if (requestState) { // response has a request state MFA required
				const email = user?.email || getEmailStorage().get() || '';
				storeAuthFactors({ email, requestState, EMAIL, SMS, nextAuthFactors });

				return mfaEnrolmentRequired
					? Router.replace('/login/protect')
					: Router.replace('/login/verification?showBackButton=false');
			} // response isn't a url (MFA required)
			return finishLogin({ url, track: trackLoginSuccess });
		})
		.catch(trackGenericException)
		.catch(() => handleKmsiLoginFailure(true));
};

export const processHttpOnly = ({ loginCtx }) => {
	const rememberedUserStorage = getRememberedUserStorage();
	new RequestBuilder(getMiddlewareUrl())
		.withData({
			loginCtx,
		})
		.post('/httponly')
		.then(({
			data: {
				rememberUser,
				trustToken,
			},
		}) => {
			// If trustToken is present, it will be a JSON object with email addresses
			if (trustToken) {
				try {
					const trustTokenPairArr = Object.entries(JSON.parse(decodeURIComponent(trustToken)));
					trustTokenPairArr.forEach(([email, token]) => getTrustTokenStorage().setIn(email, token));
				} catch (e) {
					// eslint-disable-next-line no-console
					console.log(e);
				}
			}
			// If rememberUser is present, it will be a string with the email address
			if (rememberUser) {
				rememberedUserStorage.set(rememberUser);
			} else if (rememberedUserStorage.get() === rememberUser) {
				rememberedUserStorage.remove();
			}
		})
		.catch(trackGenericException);
};
