/**
 * Return an object that describes the "fingerprint" of the user's device. This is a simplified
 * version of the device-fingerprint script that Oracle provides for invoking the "Access from an
 * unknown device event" in IDCS.
 *
 * The fingerprint that this generates is passed along with the user's credentials in the
 * authentication request.
 *
 * @returns {Object} The "fingerprint" of the user's device
 */
export default function getFingerprint() {
	/* eslint-disable no-restricted-globals */
	return JSON.stringify({
		currentTime: new Date().toString(),
		screenWidth: screen.width,
		screenHeight: screen.height,
		screenColorDepth: screen.colorDepth,
		screenPixelDepth: screen.pixelDepth,
		windowPixelRatio: window.devicePixelRatio,
		language: navigator.language,
		userAgent: navigator.userAgent,
	});
	/* eslint-enable no-restricted-globals */
}

export const generateFingerprint = () => {
	if (typeof window.getFingerprint !== 'undefined') {
		return window.getFingerprint();
	}
	// eslint-disable-next-line no-console
	console.error(new TypeError('fingerprint function not found'));
	return '';
};
