/* eslint-disable class-methods-use-this */
const { getLocaleStorage } = require('@pcid/storage-utils');

const localeCodes = {
	FR: 'fr-CA',
	EN: 'en-CA',
};

class LocaleManager {
	constructor(locale = localeCodes.EN) {
		if (LocaleManager.instance) {
			return LocaleManager.instance;
		}
		LocaleManager.instance = this;
		this.locale = locale;
		return this;
	}

	getLocale() {
		return getLocaleStorage().get() || localeCodes.EN;
	}

	getLanguage() {
		return (getLocaleStorage().get() || localeCodes.EN).slice(0, 2);
	}

	setLocale(locale) {
		getLocaleStorage().set(locale);
	}
}

const localeManager = new LocaleManager();

const parseLanguage = (locale) => locale.slice(0, 2).toLowerCase();

const injectLanguage = (url = '') => url.replace('{language}', localeManager.getLanguage());

const isRecognizedLocale = (locale) => Object.keys(localeCodes)
	.some((key) => localeCodes[key] === locale);

module.exports = {
	localeManager,
	localeCodes,
	parseLanguage,
	isRecognizedLocale,
	injectLanguage,
};
