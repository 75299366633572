import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import getFooterLinks from '@pcid/utils/get-footer-links';
import { localeManager } from '@pcid/locale-utils';

export const FeatureContext = React.createContext();
export const RelyingPartyContext = React.createContext();

export const getRPConfig = () => useContext(RelyingPartyContext);
export const getHelpURL = () => getRPConfig().helpURL;
export const getTermsURL = () => getRPConfig().termsURL;
export const getHomeURL = () => getRPConfig().homeURL;
export const getStoreName = () => getRPConfig().storeName;
export const getLoginMessage = () => getRPConfig().customLoginMessage;
export const updateConfigFile = () => getRPConfig().updateConfig;

export const getFFConfig = () => useContext(FeatureContext);
export const getForgotPasswordUrl = () => getFFConfig().forgotPasswordURL;
export const getCreateAccountUrl = () => getFFConfig().createAccountURL;

const ConfigProvider = ({ rpConfig, featureFlags, relyingParty, updateConfig, children }) => {
	const { ENVIRONMENT } = window.pcid;
	const createAccountURL = featureFlags['create-account'] && (
		typeof rpConfig.createAccountURL === 'object'
			? rpConfig.createAccountURL[ENVIRONMENT] : rpConfig.createAccountURL
	);
	const forgotPasswordURL = featureFlags['forgot-password'] && (
		typeof rpConfig.forgotPasswordURL === 'object'
			? rpConfig.forgotPasswordURL[ENVIRONMENT] : rpConfig.forgotPasswordURL
	);
	const helpURL = getFooterLinks(localeManager.getLocale(), relyingParty).help;
	const sso = featureFlags.sso && rpConfig.sso;
	const config = {
		...rpConfig,
		relyingParty,
		updateConfig,
		helpURL,
		createAccountURL,
		forgotPasswordURL,
	};

	const flags = {
		...featureFlags,
		sso,
		createAccountURL,
		forgotPasswordURL,
	};
	return (
		<RelyingPartyContext.Provider
			value={{ ...config }}
		>
			<FeatureContext.Provider
				value={{ ...flags }}
			>
				{children}
			</FeatureContext.Provider>
		</RelyingPartyContext.Provider>
	);
};

ConfigProvider.propTypes = {
	rpConfig: PropTypes.object.isRequired,
	featureFlags: PropTypes.object.isRequired,
	relyingParty: PropTypes.string.isRequired,
	updateConfig: PropTypes.func.isRequired,
	children: PropTypes.node.isRequired,
};

export const withFeatureFlags = (Component) => (props) => (
	<FeatureContext.Consumer>
		{({ ...featureFlags } = {}) => (
			<Component
				{...props}
				{...{ featureFlags }}
			/>
		)}
	</FeatureContext.Consumer>
);

export const withRelyingPartyConfig = (Component) => (props) => (
	<RelyingPartyContext.Consumer>
		{({ ...rpConfig } = {}) => (
			<Component
				{...props}
				{...{ rpConfig }}
			/>
		)}
	</RelyingPartyContext.Consumer>
);

export default ConfigProvider;
