import React from 'react';
import Router from 'next/router';
import { parse } from 'qs';
import { FormattedMessage } from 'react-intl';

import Link from '@pcid/link';
import { autoFormatMessage, AutoFormattedMessage, pcid } from '@pcid/string-utils';
import { parseLanguage, localeManager } from '@pcid/locale-utils';
import { decodeState, RequestBuilder } from '@pcid/request-utils';

import getFooterLinks from '../get-footer-links';
import { errorCodes } from '../validation';

export const handleHousekeeping = (onSubmit) => (data, {
	setStatus,
	setSubmitting,
	setFieldError,
	setFieldValue,
}) => {
	setStatus(null);
	return onSubmit(data)
		.then((result) => {
			// This is a temporary fix until Formik incorporates a didMount check into setStatus
			// Next's router's push and replace methods return a promise that resolves true, so
			// to avoid calling setStatus after the current form has been unmounted, we check to
			// see if the result is true.
			// I logged an issue with Formik here:
			// https://github.com/jaredpalmer/formik/issues/1449
			if (typeof result !== 'boolean') {
				setStatus(result);
			}
		})
		.catch((error) => {
			const { message, fieldErrors = {}, fieldValues = {} } = error;
			Object.keys(fieldErrors).forEach((name) => { setFieldError(name, fieldErrors[name]); });
			Object.keys(fieldValues).forEach((name) => { setFieldValue(name, fieldValues[name]); });

			if (typeof message === 'string') {
				setStatus({ type: 'error', message });
			} else {
				const { id, values } = message;
				values.contactUs = (
					<Link to={getFooterLinks().help}>
						<FormattedMessage id="common.contactUs" />
					</Link>
				);
				setStatus({ type: 'error', message: { id, values } });
			}
		})
		.finally(() => setSubmitting(false));
};

export const getInitialStatus = (customLoginMessage) => {
	const { asPath = '/', query = {} } = Router;
	const asQuery = parse(asPath.split('?')[1]);
	const state = decodeState(query.state);
	return asQuery.status || parse(query).status || ({
		CHANGE_PASSWORD_SUCCESS: { type: 'success', message: 'form.resetPassword.success' },
		CHANGE_EMAIL_SUCCESS: { type: 'success', message: 'form.updateEmail.success' },
	})[state.lastOp] || ({
		LOGOUT_SUCCESS: { type: 'success', message: 'form.accountManagement.logOut' },
		BAD_VALIDATION: { type: 'warning', message: 'form.accountManagement.invalidToken' },
		EXPIRED_TOKEN: { type: 'warning', message: 'form.accountManagement.sessionExpired' },
		SIGNOUT_ERROR: { type: 'error', message: 'form.accountManagement.signOutError' },
	})[state.message] || customLoginMessage;
};

export const getAcctMgmtUrl = () => window.pcid.ACCOUNT_MANAGEMENT_APP_URL;

export const logErrorCase = (payload) => {
	// Report to Middleware Logging Service when there's a generic 'Our Apologies' error
	// to debug, or 'code' format error returned, which is the deprecated IDCS style error
	const { ENVIRONMENT, MIDDLEWARE_URL } = window.pcid;

	const rootUrl = ENVIRONMENT === 'dev' ? MIDDLEWARE_URL : window.location.origin;

	new RequestBuilder(rootUrl)
		.withData({ payload })
		.post('/v1/log/')
	/* eslint-disable-next-line no-console */
		.catch(console.error);
};

export class FormError extends Error {
	static Message = (values) => ({
		// These codes are referenced internally
		OFFLINE: {
			id: 'form.error.offline',
			values: {
				contactUs: (
					<Link to={getFooterLinks().help}>
						{autoFormatMessage('common.contactUs')}
					</Link>
				),
			},
		},
		UNKNOWN: {
			id: 'form.error.unknown',
			values: {
				contactUs: (
					<Link
						to={`https://loblaw.force.com/pcid/s/?language=${parseLanguage(localeManager.getLocale())}`}
						target="_blank"
					>
						<AutoFormattedMessage id="common.contactUs" />
					</Link>
				) },
		},
		DUPLICATE_EMAIL_ID: {
			id: 'form.updateEmail.emailExistingError',
			values: {
				pcid: pcid({ _case: 'title' }),
				...values,
			},
		},
		EMAIL_NOT_VALID: { id: 'form.updateEmail.emailInvalidError' },
		EMAIL_UNAVAILABLE: { id: errorCodes.emailExists },
		INVALID_REQUEST_STATE: { id: 'form.error.expiredMFASession' },
		INVALID_PHONE_NUMBER: { id: 'form.accountManagement.numberInvalidError' },
		INVALID_PASSCODE: {
			id: 'form.error.wrongCode',
			values: { cta: autoFormatMessage('common.cta.resendCode') },
		},
		SMS_MFA_ALREADY_ENROLLED: { id: 'form.accountManagement.currentlyEnrolled' },
		INVALID_TOKEN: {
			id: 'form.updateEmail.invalidTokenError',
			values: { signIn: (
				<Link to={`${getAcctMgmtUrl()}?lang=${parseLanguage(localeManager.getLocale())}`}>
					{autoFormatMessage('form.common.signIn').toLowerCase()}
				</Link>
			) },
		},
		USER_NOT_FOUND: {
			id: 'form.updateEmail.expiredLinkError',
			values: { signIn: (
				<Link to={`${getAcctMgmtUrl()}?lang=${parseLanguage(localeManager.getLocale())}`}>
					{autoFormatMessage('form.common.signIn').toLowerCase()}
				</Link>
			) },
		},

		// These codes come from IDCS
		'AUTH-1027': { id: 'form.error.locked' },
		'AUTH-3001': { id: 'form.error.wrongCredentials' },
		'AUTH-1105': {
			id: 'form.error.wrongCode',
			values: { cta: autoFormatMessage('common.cta.resendCode') },
		},
		'AUTH-1104': { id: 'form.error.expiredCode' },
		'AUTH-1120': { id: 'form.error.expiredLoginCtx' },
		'SHAPE-429': { id: 'form.error.shape429' },
	})

	constructor(payload = {}) {
		super(payload);
		const { error, errorDescription, id, values, status, ...rest } = payload;
		this.message = (error && FormError.Message(values)[error])
				|| (id && { id, values })
				|| FormError.Message().UNKNOWN;

		if (!this.message.values) this.message.values = {};
		this.status = status || { type: 'error' };
		this.tags = {
			errorCode: error,
			errorType: 'server',
			errorMessage: autoFormatMessage(this.message.id),
			errorDescription,
			...rest,
		};

		if (this.message.id === FormError.Message().UNKNOWN.id) {
			logErrorCase(payload);
		}

		return this;
	}
}
