import React from 'react';

export const GlobalNotificationContext = React.createContext({
	changeStatus: () => {},
	showNotification: false,
	notificationPosition: 'below',
	text: 'form.createAccount.confirmPassword',
	status: 'success',
	dismissNotification: () => {},
});

export const withGlobalNotification = (Component) => (props) => (
	<GlobalNotificationContext.Consumer>
		{({
			changeStatus,
			showNotification,
			text,
			status,
			dismissNotification,
			notificationPosition,
		}) => (
			<Component
				{...props}
				{...{
					changeStatus,
					showNotification,
					status,
					text,
					dismissNotification,
					notificationPosition,
				}}
			/>
		)}
	</GlobalNotificationContext.Consumer>
);
