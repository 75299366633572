import {
	getRelyingPartyStorage,
} from '@pcid/storage-utils';

export const Event = {
	CUSTOM_LINK: 'custom_link',
	PAGE_VIEW: 'page_view',
	EXCEPTION: 'exception',
	CLICK: 'click',
};

const track = (event) => (data) => {
	if (!data) return;
	const payload = { event, loginSource: getRelyingPartyStorage().get(), ...data };
	if (window.__NEXT_DATA__ && window.__NEXT_DATA__.buildId === 'development') {
		// If we're developing locally, it's useful to see analytics events in the console
		// eslint-disable-next-line no-console
		console.log(`%cmetrics::${event}`, `color:${({
			[Event.PAGE_VIEW]: 'green',
			[Event.CUSTOM_LINK]: 'blue',
			[Event.CLICK]: 'purple',
			[Event.EXCEPTION]: 'red',
		})[event]};font-weight:bold`, payload);
	}
	if (global.window.dataLayer) {
		global.window.dataLayer.push(payload);
	}
};

track.pageView = track(Event.PAGE_VIEW);
track.customLink = track(Event.CUSTOM_LINK);
track.exception = track(Event.EXCEPTION);
track.click = track(Event.CLICK);

export default track;

export const trackSnowplowEvent = (payload) => {
	if (global.window.analyticsLayer) {
		global.window.analyticsLayer.push(payload);
	}
};

// Here's a pattern we've seen many times before... `tap` wraps a function and makes it
// promise chain friendly--basically a function that is wrapped with tap can be inserted
// into a promise chain without affecting the flow of data. The wrapped function also has
// a `withMeta` method, which allows you to inject metadata as a second argument into your
// wrapped function.
export const tap = (doSomethingWithThe) => {
	const tapper = (result) => {
		doSomethingWithThe(result);
		return result;
	};
	tapper.withMeta = (meta) => (result) => {
		doSomethingWithThe(result, meta);
		return result;
	};
	return tapper;
};

export const sha256 = async (text) => {
	let hashString;
	try {
		const encodedText = new TextEncoder().encode(text);
		const hashBuffer = await crypto.subtle.digest('SHA-256', encodedText);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		hashString = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
	} catch {
		hashString = null;
	}
	return hashString;
};
